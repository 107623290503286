
export const layer1 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 30,
        endValue: -10,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
]

export const layer2 = [
  {
    start: 0,
    end: 4000,
    properties: [
      {
        startValue: 0,
        endValue: -100,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
]

export const singleLayer = [
  {
    start: 'self',
    duration: '200vh',
    properties: [
      {
        startValue: -10,
        endValue: 10,
        unit: 'vh',
        property: 'translateY',
      },
    ],
  },
]

export const fixed = [
  {
    start: 'self',
    startOffset: '0vh',
    duration: '10vh',
    properties: [
      {
        startValue: 0,
        endValue: 1,
        property: 'opacity',
      },
    ],
  },
  {
    start: 'self',
    startOffset: '175vh',
    duration: '10vh',
    properties: [
      {
        startValue: 1,
        endValue: 0,
        property: 'opacity',
      },
    ],
  },
]
